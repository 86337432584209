var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "authentication-layout",
    [
      _c(
        "header",
        {
          staticClass: "header",
          attrs: { slot: "form-header" },
          slot: "form-header",
        },
        [
          _c(
            "v-alert",
            {
              staticClass: "mb-4",
              attrs: { value: _vm.errorMessage, type: "error" },
            },
            [_vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    ")]
          ),
          _c("h2", { staticClass: "title" }, [
            _vm._v("Escoha uma das opções de cadastro."),
          ]),
        ],
        1
      ),
      _c(
        "template",
        { slot: "form-content" },
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { "justify-space-around": "" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { "max-width": "300", top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-card",
                                _vm._g(
                                  {
                                    staticClass: "pa-2",
                                    attrs: { height: "130", width: "130" },
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        "justify-center": "",
                                        "align-center": "",
                                        "fill-height": "",
                                        column: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: { name: "register-entity" },
                                          },
                                        },
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              width: "73",
                                              height: "65",
                                              src: _vm.getImageUrl(
                                                "entity.png"
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("span", { staticClass: "card-name" }, [
                                        _vm._v(
                                          "\n                  Entidade\n                "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n            Destinado a empresas que ainda não possuam cadastro no sistema.\n            O responsável indicado nessa etapa será considerado o administrador\n            daquela empresa e ficará responsável pela aprovação dos demais usuários.\n          "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { "max-width": "300", top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-card",
                                _vm._g(
                                  {
                                    staticClass: "pa-2",
                                    attrs: { height: "130", width: "130" },
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        "justify-center": "",
                                        "align-center": "",
                                        "fill-height": "",
                                        column: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: { name: "register-user" },
                                          },
                                        },
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              width: "70",
                                              height: "47",
                                              src: _vm.getImageUrl("user.png"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("span", { staticClass: "card-name" }, [
                                        _vm._v(
                                          "\n                  Vinculado\n                "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n            Destinado a empresas que possuam cadastro no sistema e que o\n            usuário deseja se vincular para ter acesso aos MTRs.\n            Após o cadastro, o Administrador da emprasa deverá autorizar o\n            acesso através da tela de gerenciamento da empresa.\n          "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "navigation" },
        [
          _c(
            "v-btn",
            {
              staticClass: "link",
              attrs: { flat: "", small: "", color: "primary", to: "login" },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-account-circle"),
              ]),
              _c("span", { staticClass: "text" }, [_vm._v("Voltar ao login")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }