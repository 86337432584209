<template>
  <authentication-layout>
    <header slot="form-header" class="header">
      <v-alert class="mb-4" :value="errorMessage" type="error">
        {{errorMessage}}
      </v-alert>
      <h2 class="title">Escoha uma das opções de cadastro.</h2>
    </header>

    <template slot="form-content">
      <v-container>
        <v-layout justify-space-around>
          <v-tooltip
            max-width="300"
            top>
            <template v-slot:activator="{ on }">
              <v-card
                class="pa-2"
                height="130"
                width="130"
                v-on="on"
              >
                <v-layout justify-center align-center fill-height column>
                  <router-link :to="{ name: 'register-entity' }">
                    <v-img
                      width="73"
                      height="65"
                      :src="getImageUrl('entity.png')"></v-img>
                  </router-link>
                  <span
                    class="card-name"
                  >
                    Entidade
                  </span>
                </v-layout>
              </v-card>
            </template>
            <span>
              Destinado a empresas que ainda não possuam cadastro no sistema.
              O responsável indicado nessa etapa será considerado o administrador
              daquela empresa e ficará responsável pela aprovação dos demais usuários.
            </span>
          </v-tooltip>
          <v-tooltip
            max-width="300"
            top>
            <template v-slot:activator="{ on }">
              <v-card
                class="pa-2"
                height="130"
                width="130"
                v-on="on">
                <v-layout justify-center align-center fill-height column>
                  <router-link :to="{ name: 'register-user' }">
                    <v-img
                      width="70"
                      height="47"
                      :src="getImageUrl('user.png')"/>
                  </router-link>
                  <span
                    class="card-name"
                  >
                    Vinculado
                  </span>
                </v-layout>
              </v-card>
            </template>
            <span>
              Destinado a empresas que possuam cadastro no sistema e que o
              usuário deseja se vincular para ter acesso aos MTRs.
              Após o cadastro, o Administrador da emprasa deverá autorizar o
              acesso através da tela de gerenciamento da empresa.
            </span>
          </v-tooltip>
        </v-layout>
      </v-container>
    </template>

    <template slot="navigation">
      <v-btn flat small color="primary" class="link" to="login">
        <v-icon left>mdi-account-circle</v-icon>
        <span class="text">Voltar ao login</span>
      </v-btn>
    </template>
  </authentication-layout>
</template>

<script>
import AuthenticationLayout from '@/layouts/AuthenticationLayout.vue';
import RegisterForm from '@/components/auth/RegisterUserForm.vue';
import { mapActions } from 'vuex';
import AuthService from '@/services/auth-service';

export default {
  name: 'Register',
  data() {
    return {
      pending: false,
      errorMessage: '',
    };
  },
  components: {
    AuthenticationLayout,
    RegisterForm,
  },
  methods: {
    getImageUrl(image) {
      return require(`@/assets/${image}`); // eslint-disable-line
    },
  },
};
</script>

<style lang="scss">
  .header {
    > .title {
      font-family: 'Nexa', sans-serif !important;
      font-weight: 400;
      font-size: 1.125rem !important;
      color: #396753;
    }
  }
  .card-name{
    position: absolute;
    top: 100px;
  }
</style>
